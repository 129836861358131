import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from "react-dom/client";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// import { configureStore } from '@reduxjs/toolkit';
import { createStore } from 'redux';
import {Provider} from 'react-redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import MainReducer from './Redux/MainReducer';

const globalStore = createStore(
  MainReducer,
  composeWithDevTools()
)


//  const globalStore = createStore(MainReducer, 
//  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())


// globalStore.subscribe(() => {
//     // console.log(globalStore.getState());
//  })

// ReactDOM.render(
//   <Provider store={globalStore}>
//     {/* <React.StrictMode> */}
//       <App />
//     {/* </React.StrictMode> */}
//   </Provider>,
//   document.getElementById('root')
// );

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={globalStore}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
