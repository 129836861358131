import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Welcome from "./Components/Welcome/Welcome";
import Game from "./Components/Game/Game";
import TopPlayers from "./Components/TopPlayers/TopPlayers";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Welcome/>
  },
  {
    path: "/play",
    element: <Game/>
  },
  {
    path: "/top-players",
    element: <TopPlayers/>
  }
])

function App() {
  return <RouterProvider router={router}/>
}

export default App;
